/* eslint-disable @typescript-eslint/space-before-function-paren */
import axios from 'axios'
import {
  CategoryModel,
  GetNOldTickets,
  TicketModel,
  TicketsWithPagination,
  TicketToSubmitModel,
  UpdateTicketToSubmit,
  UserProfileModel
} from '../entities'
import { IDatabaseFilter } from 'hooks/useGetOldTickets'

// const url = process.env.REACT_APP_CONNECTOR_URL
export const api = axios.create({
  baseURL: process.env.REACT_APP_CONNECTOR
})

export async function getNTickets(
  userLogin: string,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  max_tickets: number,
  status: string
): Promise<GetNOldTickets[]> {
  const payload = {
    user_login: userLogin,
    max_tickets,
    status
  }
  const response = await api.post('/ticket/index', payload)
  return response.data?.tickets
}

export async function getTicket(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  called_id: string | number
): Promise<TicketModel> {
  const payload = {
    called_id
  }
  const response = await api.post('/ticket/show', payload)
  return response.data
}

export async function getUsersByName(userName: string): Promise<any> {
  const response = await api.get(`/user/show-by-name?name=${userName}&size=${250}`)
  return response.data.users
}

export async function storeTicket(payload: TicketToSubmitModel): Promise<{
  ticket_id: string
  message: string
}> {
  const response = await api.post('/ticket/store', payload)
  return response.data
}

export async function getUserProfile(userLogin: string): Promise<UserProfileModel> {
  const response = await api.post('/user/show', { user_login: userLogin })
  return response.data
}

export async function updateStatusTicket(payload: UpdateTicketToSubmit): Promise<any> {
  const response = await api.post('/ticket/followup/', payload)
  return response.data
}

export async function getGLPIFileBase64(fileId: string | number): Promise<any> {
  const response = await api.get(`/ticket/file/${fileId}`)
  return response.data
}

// Tickets legados da cervello
// eslint-disable-next-line @typescript-eslint/naming-convention
export async function getOldTickets(userLogin: string, startDate: number | null, endDate: number | null, limit: number, page: number, base: IDatabaseFilter): Promise<TicketsWithPagination> {
  const response = await api.get(`/${base}/ticket/?login=${userLogin}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}&limit=${limit}&page=${page}&order=ASC`)

  return response.data
}

export async function getOldTicket(id: string | number, base: IDatabaseFilter): Promise<TicketModel> {
  const response = await api.get(`/${base}/ticket/${id}`)
  return response.data
}
